
.map-monitoring-marker-parent {
    position: relative;

    transition: transform 250ms;

    &:hover {
        transform: scale(1.2);
    }
    &:active {
        transform: scale(0.9);
    }
}


.map-monitoring-marker {
    position: absolute;
    left: 0px;
    top: 0px;
    border-radius: 50%;

    transform: translate(-50%, -50%);

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer !important;
}


.map-monitoring-main-marker {
    width: 25px;
    height: 25px;

    color: black;

    border: solid;
    border-width: 1px;
    border-color: black;
}

.map-monitoring-secondary-marker {
    width: 10px;
    height: 10px;

    border: solid;
    border-width: 1px;
    border-color: black;
}

.map-monitoring-location-marker {
    width: 30px;
    height: 30px;

    background-color: #1c1c1c;
}

.map-monitoring-start-marker {
    width: 25px;
    height: 25px;

    background-color: #1c1c1c;
}