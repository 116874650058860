/* Variables */
$white: #ffffff65;
$teal: #16a085;

$primary-color: $white;
$secondary-color: $teal;

.list-grid-toggle {
transform: scale(0.5);

  width: 40px;
  height: 40px;
  //padding: 25px;
  background: shade($secondary-color, 8%);
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  font-weight: 200;
  letter-spacing: 3px;
  text-align: center;
  text-shadow: 2px 2px 4px shade($secondary-color, 20%);
  text-transform: uppercase;
  transition: all 0.15s ease;
  user-select: none;
  
  &:hover {
    //padding: 30px;
    background: shade($secondary-color, 10%);
  }
  
  .label {
    display: none;
  }
}

.grid-list-icon {
  width: 40px;
  margin-bottom: 10px;
}

.grid-list-icon-bar {
  position: relative;
  width: 40px;
  height: 10px;
  margin: 0 auto 5px auto;
  background: $primary-color;
  box-shadow: 2px 2px 4px shade($secondary-color, 20%);
  transition: all 0.15s ease;
  
  &:last-child {
    margin-bottom: 0;
  }
  
  &:before,
  &:after {
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    background: $primary-color;
    box-shadow: 2px 2px 4px (shade($secondary-color, 20%));
    content: '';
    opacity: 0;
    transition: all 0.15s ease;
  }
  
  &:before {
    left: -15px;
  }

  &:after {
    right: -15px;
  }
}

.grid-list-icon-grid {
  .grid-list-icon-bar {
    width: 10px;
    
    &:before,
    &:after {
      opacity: 1;
    }
    
    &:before {
      left: 0;
      transform: translateX(-15px);
    }
    
    &:after {
      right: 0;
      transform: translateX(15px);
    }
  }
}