.icon-btns {
    display: flex;
    align-items: center;
    padding-right: 1px;

    button {
        appearance: none;
        border: none;
        aspect-ratio: 1;

        transition: 250ms;

        border-radius: 5px;

        &:hover {
            background: #1c1c1c;
            color: white;
        }
    }
}
