.app2 {
    .header {
        position: sticky;
        top: 0px;
        z-index: 301;
        background: #000000dd;
        border-bottom: 1px solid #ffffff20;
        backdrop-filter: saturate(100%) blur(12px);
    }

    .menu-button {
        appearance: none;
        border: none;
        background: none;

        padding-inline: 0.5rem;
        padding-block: 0.5rem;
        border-radius: 7px;
        color: white;

        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0.25rem;

        transition: 250ms;

        &:hover {
            background: #ffffff20;
        }
    }

    .menu-button-selected {
        background: #ffffff20;
        border: 1px solid #ffffff40;
    }

    .menu-dropdown {
        max-height: 75vh;
        overflow: auto;

        position: fixed;
        background: #f4f4f4;
        // height: 300px;
        width: 100%;

        z-index: 3000;
        animation: opacity-fade-in-lg-anim 250ms;

        left: 0px;
        top: 3rem;

        padding-inline: 6rem;
        padding-block: 3rem;

        box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
            0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    }

    .menu-dropdown-button {
        width: 100%;
        max-width: 300px;
        appearance: none;
        border: none;
        background: #00000015;

        padding-inline: 0.5rem;
        padding-block: 0.5rem;
        border-radius: 7px;
        color: black;

        transition: 250ms;

        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0.25rem;

        margin-bottom: 0.5rem;

        .right-arrow {
            transition: 250ms;
            opacity: 0;
            color: white !important;
        }
        &:hover {
            background: #1c1c1c;
            color: white;

            .right-arrow {
                opacity: 1;
            }
        }
    }
}
