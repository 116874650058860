.user-assignation-button-pop-over {
    background-color: white;
    box-shadow: 0px 13px 27px -5px rgba(50, 50, 93, 0.25),
        0px 8px 16px -8px rgba(0, 0, 0, 0.3),
        0px -6px 16px -6px rgba(0, 0, 0, 0.025);
    // padding: 1rem;
    border-radius: 15px;
}

.user-assignation-button-pop-over-root {
    transform-origin: top right;
    animation: user-assignation-button-pop-over-fade-in 250ms;
}

.user-assignation-button-pop-over-root-reversed {
    transform-origin: top left;
    animation: user-assignation-button-pop-over-fade-in 250ms;
}

.user-assignation-button-pop-over:has(.robo-class-name-tool-popover) {
    border-radius: 5px;
    transform-origin: top center;
}

.user-assignation-selection-item {
    width: 300px;
    height: 45px;

    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-inline: 1rem;

    transition: background-color 250ms;
    cursor: pointer;

    &:hover {
        background-color: #0f62fe20;
    }
}

.user-assignation-selection-item[data-selected="true"] {
    background-color: #00999940;
}

@keyframes user-assignation-button-pop-over-fade-in {
    0% {
        opacity: 0;
        transform: scale(0.4);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
