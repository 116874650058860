.custom-input-parent {
    display: flex;
    align-items: center;
    // gap: 5px;
    gap: 10px;

    background-color: #1c1c1c;
    // padding-inline: 20px;
    padding-left: calc(0.5rem + 16px - 5px);

    padding-right: 20px;
    transition: 250ms;

    &:hover {
        background-color: #2b2b2b;
    }

    input {
        outline: none;
        height: 45px;
        width: calc(100% - 20px);
        background-color: transparent;
        border: none;

        color: white;
    }
}
