.system-values-type-btn {
    border-radius: 25px;
    transition: 250ms;
    cursor: pointer;

    &:active {
        background-color: #f5f5f5;
    }

    &:hover {
        box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
        transform: translateY(-5px);
    }
}

.system-values-back-link-btn {
    opacity: 0.6;
    transition: 250ms;

    &:hover {
        opacity: 1;
    }
}
