@keyframes timeline-calendar-group-animate {
    0% {
        transform: translateY(-40%);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}
