.my-course-card {
    cursor: pointer;
    transition: 250ms;

    &:hover {
        box-shadow: 0px 8px 17px 2px rgba(0, 0, 0, 0.14),
            0px 3px 14px 2px rgba(0, 0, 0, 0.12),
            0px 5px 5px -3px rgba(0, 0, 0, 0.2);
        transform: translateY(-5px);
    }
}
