.advanced-notes-items {
    transition: 250ms;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
            0px 2px 4px -1px rgba(0, 0, 0, 0.06);

        .advanced-notes-items-thumb-icon {
            transform: scale(1.2);
        }
    }
}

@keyframes advanced-notes-section-fade-in {
    0% {
        transform: translateY(-60%);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes advanced-notes-button-fade-in {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

@keyframes advanced-notes-content-fade-in {
    0% {
        transform: translateX(40%);
        opacity: 0;
    }
    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

.advanced-notes-items-tree-new-btn {
    cursor: pointer;
    &:hover {
        opacity: 1 !important;
    }
}

.advanced-notes-items-tree {
    transition: 250ms;
}

.advanced-notes-items-tree-root {
    &:hover {
        .advanced-notes-items-tree {
            transform: translateY(-5px);
            box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
                0px 2px 4px -1px rgba(0, 0, 0, 0.06);

            .advanced-notes-items-thumb-icon-tree {
                // transform: scale(1.2);
                padding: 0px !important;
                div {
                    border-radius: 0px !important;
                    border-top: none !important;
                    border-bottom: none !important;
                    border-left: none !important;
                }
            }
        }
    }
}

.advanced-notes-items-new-type {
    cursor: pointer;
    transition: transform 250ms;
    p {
        transition: opacity 250ms;
    }
    &:hover {
        transform: scale(1.1);
        p {
            opacity: 1 !important;
        }
    }
}
