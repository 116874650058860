.report-view-row {
    display: flex;

    &:nth-child(even) {
        background: #00000007;
    }

    &:nth-child(odd) {
        background: white;
    }
}

.report-view-cell {
    display: flex;
    align-items: center;

    border-right: 1px solid #00000020;
    &:nth-last-child(1) {
        border-right: none;
    }
}

@keyframes report-view-item-group-animate {
    0% {
        transform: translateY(-20%);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}
