.dashboard-rest {
    .header {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        border: 1px solid #00000020;

        z-index: 2;

        .bx--label {
            display: none;
        }
    }

    .subheader {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        padding-block: 0.25rem;

        border: 1px solid #00000020;
        background: #f4f4f4;

        z-index: 2;

        .bx--label {
            display: none;
        }

        .bx--text-input__field-outer-wrapper {
            width: 220px !important;
        }

        .bx--form-item {
            flex: unset !important;
        }
    }

    .mobile-subheader {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        padding-block: 0.25rem;

        border: 1px solid #00000020;
        background: #f4f4f4;

        z-index: 2;

        .bx--label {
            display: none;
        }

        .bx--list-box__wrapper {
            width: 100%;
        }
    }

    .content {
        padding: 1rem;
        padding-bottom: 110px;
    }

    .card-section {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        padding: 1rem;
    }

    .rb-card {
        background: #fafafa;
        border-radius: 10px;
        position: relative;
        min-height: 100px;

        .rb-card-color {
            width: 128px;
            height: 128px;
            border-radius: 50%;

            position: absolute;
            left: -40px;
            top: -60px;

            z-index: 1;
        }

        .rb-card-content {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;

            padding: 7px;

            z-index: 2;

            // display: flex;

            .rb-card-icon {
                position: absolute;
                left: 7px;
                top: 7px;
            }
        }
    }

    .rb-card-header {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        padding-block: 0.5rem;
        padding-inline: 1rem;
        background: #f4f4f4;
        // border-bottom: 1px solid #00000020;
    }
}
