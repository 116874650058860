
.system-settings-group-btn {
    width: 100%;
    height: 45px;
    padding: 0.25rem 1rem 0.25rem 1rem;
    gap: 0.5rem;
    
    display: flex;
    align-items: center;

    border-radius: 5px;
    transition: 250ms;
    cursor: pointer;
    
    background-color: #f2f2f2;

    &:active {
        background-color: #f5f5f5
    }

    &:hover {
        //box-shadow: 0px 25px 50px -12px rgba(0,0,0,0.25) 
        background-color: #e0e0e0;
    }
}

.settings-tiles {
    .bx--tile {
        // width: 200px;
        height: unset !important;

        background-color: #f4f4f4;

        color: black;

        &:hover {
            background-color: #e5e5e5;
        }
    }
}

@keyframes settings-back-fade-in {
    0% {
        transform: translateX(100px);
        opacity: 0.6;
    }
    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

@keyframes settings-view-fade-in {
    0% {
        transform: translateX(250px);
        opacity: 0.6;
    }
    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

