

.white-checkbox {

    position: relative;
 
    input {
        height: 25px;
        width: 25px;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        border: 1px solid #cecece;
        border-radius: 4px;
        outline: none;
        transition-duration: 0.3s;
        background-color: black;
        cursor: pointer;
    }

}

.checked-box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    width: 18px;
    height: 18px;
    border-radius: 4px;
    pointer-events: none;

    background: #e7e7e7;
}