.dashboard-view-0 {
    background: linear-gradient(315deg, darken(#631a86, 15%), #631a86);

    h3 {
        font-size: 20px;
        //color: lighten(#631A86, 25%);
        color: white;
        font-weight: 600;
    }
}

.dashboard-view-1 {
    background: linear-gradient(315deg, darken(#3083dc, 15%), #3083dc);

    h3 {
        font-size: 20px;
        //color: lighten(#3083DC, 25%);
        color: white;
        font-weight: 600;
    }
}

.dashboard-view-2 {
    background: linear-gradient(315deg, darken(#197278, 15%), #197278);

    h3 {
        font-size: 20px;
        color: white;
        font-weight: 600;
    }
}

.dashboard-view-3 {
    background: linear-gradient(315deg, darken(#f45866, 15%), #f45866);

    h3 {
        font-size: 20px;
        //color: lighten(#F45866, 25%);
        color: white;
        font-weight: 600;
    }
}

.dashboard-bottom-view-0 {
    background: darken(#631a86, 20%);

    h3 {
        font-size: 18px;
        color: white;
        font-weight: 600;
    }
}

.dashboard-bottom-view-1 {
    background: darken(#3083dc, 40%);

    h3 {
        font-size: 18px;
        color: white;
        font-weight: 600;
    }
}

.dashboard-bottom-view-2 {
    background: darken(#197278, 20%);

    h3 {
        font-size: 18px;
        color: white;
        font-weight: 600;
    }
}

.dashboard-bottom-view-3 {
    background: darken(#f45866, 40%);

    h3 {
        font-size: 18px;
        color: white;
        font-weight: 600;
    }
}

.rounded-dashboard-card {
    // .dashboard-card {
    //     border-radius: 25px !important;
    //     box-shadow: 0px 25px 50px -12px #00000060;
    //     background: white !important;
    //     margin: 25px;
    // }
}
