.day-picker-show-time-scheduler-button {
    opacity: 0.65;
    transition: 250ms;

    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    outline: inherit;

    &:disabled {
        opacity: 0.25;
    }

    &:enabled {
        cursor: pointer;
        &:hover {
            opacity: 1;
        }
    }
}
