.calendar-button-pop-over {
    background-color: #f4f4f4;
    box-shadow: 0px 13px 27px -5px rgba(50, 50, 93, 0.25),
        0px 8px 16px -8px rgba(0, 0, 0, 0.3),
        0px -6px 16px -6px rgba(0, 0, 0, 0.025);
    // padding: 1rem;
    border-radius: 15px;
    // overflow: hidden;

    border-width: 1px;
    border-style: solid;
    border-color: #00000020;
}

.calendar-button-pop-over-root {
    transform-origin: left;
    animation: calendar-button-pop-over-fade-in 250ms;
}

.calendar-filter-pop-over {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 90000;
    padding: 0.5rem;
    width: 250px;

    transform-origin: left;
    animation: calendar-button-pop-over-fade-in 250ms;

    background-color: #f4f4f4;
    box-shadow: 0px 13px 27px -5px rgba(50, 50, 93, 0.25),
        0px 8px 16px -8px rgba(0, 0, 0, 0.3),
        0px -6px 16px -6px rgba(0, 0, 0, 0.025);

    border-radius: 15px;
    border: 1px solid #00000020;
}

.calendar-filter-pop-over-hover {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 90000;
    padding: 0.5rem;
    width: 250px;

    transform-origin: left;
    animation: calendar-button-pop-over-fade-in 250ms;

    box-shadow: 0px 13px 27px -5px rgba(50, 50, 93, 0.25),
        0px 8px 16px -8px rgba(0, 0, 0, 0.3),
        0px -6px 16px -6px rgba(0, 0, 0, 0.025);

    border-radius: 5px;
    border: 1px solid #00000020;
}

@keyframes calendar-button-pop-over-fade-in {
    0% {
        opacity: 0;
        transform: scale(0.4);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.calendar-icon-button {
    appearance: none;
    border: none;

    transition: 250ms;
    cursor: pointer;

    background: #ffffff40;
    border: 1px solid #ffffff50;
    color: black;

    width: 35px;
    height: 35px;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background: white;
        transform: scale(1.1);
    }

    &:active {
        transform: scale(0.9);
    }
}
