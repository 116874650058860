@keyframes draft-advanced-background-fade-in {
    0% {
        background: rgba(0, 0, 0, 0);
    }
    100% {
        background: rgba(0, 0, 0, 0.65);
    }
}

@keyframes draft-advanced-panel-fade-in {
    0% {
        opacity: 0;
        transform: translateX(25%);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

.draft-advanced-template-card {
    cursor: pointer;
    transition: 250ms;

    &:hover {
        box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
            0px 4px 6px -2px rgba(0, 0, 0, 0.05);
        transform: translateY(-2px);
    }
}
