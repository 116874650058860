.reporting-dashboard-item {
    display: flex;
    align-items: center;

    padding-block: 1rem;
    padding-inline: 1.5rem;

    transition: 250ms;
    cursor: pointer;
    color: black;

    border-bottom: 1px solid #00000020;
    &:hover {
        background: #00000010;
    }
}

.reporting-dashboard-card {
    background: #f4f4f4;

    min-width: 350px;
    width: 350px;
    height: 100%;
    border-radius: 10px;
    border: "1px solid #00000010";

    transition: 250ms;
    &:hover {
        background: #141414;
        color: white;

        // transform: scale(1.02);
        border-radius: 0px;
        margin-block: -1rem;
        margin-inline: -0.5rem;
        width: calc(375px + (0.5rem * 2));
        min-width: calc(375px + (0.5rem * 2));
        height: calc(100% + (1rem * 2));

        .reporting-dashboard-card-inner {
            border: none;
        }
    }

    .reporting-dashboard-card-inner {
        border: 1px solid #00000020;
    }
}
