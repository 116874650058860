.third-party-pos {
    .t-pos-card {
        border-radius: 5px;
        // box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1),
        //     0px 10px 10px -5px rgba(0, 0, 0, 0.04);

        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
            0px 1px 2px 0px rgba(0, 0, 0, 0.06);

        padding: 0.5rem;
    }

    .t-pos-btn {
        border-radius: 10px;
        height: 40px;
    }

    .t-pos-btn-icon {
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.white-out-third-party-pos-card-bg {
    .t-pos-card-bg {
        background: white !important;
    }
}
