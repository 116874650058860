.label-designer-root {
    width: 100%;
    height: calc(100% - 3rem);

    display: flex;
    flex-direction: column;

    position: fixed;

    *:not(input):not(textarea) {
        user-select: none;
    }
}

.label-designer-header {
    width: 100%;
    height: 3rem;

    color: white !important;

    display: flex;

    align-items: center;
}

.level-designer-component-root {
    min-width: 65px;
    width: 65px;
    height: 100%;

    background: white;

    border-right: solid;
    border-right-width: 1px;
    border-right-color: #a7a7a799;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    gap: 7px;
}

.level-designer-inspector-root {
    width: 285px;
    min-width: 285px;
    // width: 300px;
    height: 100%;

    background: white;

    border-left: solid;
    border-left-width: 1px;
    border-left-color: #a7a7a799;

    display: flex;
    flex-direction: column;
    min-height: 0px;
}

.level-designer-inspector-content {
    width: 100%;
    padding-top: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 1rem;

    overflow-y: auto;
    overflow-x: hidden;

    flex: 1;
    min-height: 0px;
}

.label-designer-header-button {
    width: 3rem;
    height: 3rem;

    display: flex;
    justify-content: center;
    align-items: center;
}

.label-designer-canvas-content-grid {
    background-image: linear-gradient(
            to right,
            rgba(1, 1, 1, 0.2) 1px,
            transparent 1px
        ),
        linear-gradient(to bottom, rgba(1, 1, 1, 0.2) 1px, transparent 1px);
    background-size: 25px 25px;
}

.label-designer-canvas-content {
    width: 100%;
    height: 100%;

    background-color: white;

    //box-shadow: 0px 16px 24px 2px rgba(0,0,0,0.14) , 0px 6px 30px 5px rgba(0,0,0,0.12) , 0px 8px 10px -7px rgba(0,0,0,0.2) ;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.14),
        0px 0px 10px 0px rgba(0, 0, 0, 0.12),
        0px 0px 4px -1px rgba(0, 0, 0, 0.2);
    // box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.24) , 0px 0px 10px 0px rgba(0,0,0,0.22) , 0px 0px 4px -1px rgba(0,0,0,0.12) ;
    // box-shadow: 0px 0px 17px 2px rgba(0,0,0,0.14) , 0px 0px 14px 2px rgba(0,0,0,0.12) , 0px 0px 5px -3px rgba(0,0,0,0.2) ;

    border-radius: 5px;

    transition: background-color 250ms;
}

.label-designer-canvas-content-highlighted {
    background-color: rgb(255, 255, 136);
    // border-color: yellow;
    // border-style: solid;
    // border-width: 2px;
}

.label-designer-canvas-root {
    width: 100%;
    flex: 1;

    display: flex;
    // justify-content: center;
    // align-items: center;
    // padding: 1rem;

    background-color: #f9f4ed;

    overflow: auto;
    position: relative;

    // padding: 50px 0;
    // box-sizing: border-box;
    // div {
    //     margin: auto 0;
    // }
}

.label-designer-canvas-root-grid-background {
    background-image: 
    // linear-gradient(to right, grey 1px, transparent 1px),
    // linear-gradient(to bottom, grey 1px, transparent 1px);
        linear-gradient(
            0deg,
            transparent 9%,
            rgba(0, 0, 0, 0.2) 10%,
            rgba(0, 0, 0, 0.2) 12%,
            transparent 13%,
            transparent 29%,
            rgba(0, 0, 0, 0.1) 30%,
            rgba(0, 0, 0, 0.1) 31%,
            transparent 32%,
            transparent 49%,
            rgba(0, 0, 0, 0.1) 50%,
            rgba(0, 0, 0, 0.1) 51%,
            transparent 52%,
            transparent 69%,
            rgba(0, 0, 0, 0.1) 70%,
            rgba(0, 0, 0, 0.1) 71%,
            transparent 72%,
            transparent 89%,
            rgba(0, 0, 0, 0.1) 90%,
            rgba(0, 0, 0, 0.1) 91%,
            transparent 92%,
            transparent
        ),
        linear-gradient(
            90deg,
            transparent 9%,
            rgba(0, 0, 0, 0.2) 10%,
            rgba(0, 0, 0, 0.2) 12%,
            transparent 13%,
            transparent 29%,
            rgba(0, 0, 0, 0.1) 30%,
            rgba(0, 0, 0, 0.1) 31%,
            transparent 32%,
            transparent 49%,
            rgba(0, 0, 0, 0.1) 50%,
            rgba(0, 0, 0, 0.1) 51%,
            transparent 52%,
            transparent 69%,
            rgba(0, 0, 0, 0.1) 70%,
            rgba(0, 0, 0, 0.1) 71%,
            transparent 72%,
            transparent 89%,
            rgba(0, 0, 0, 0.1) 90%,
            rgba(0, 0, 0, 0.1) 91%,
            transparent 92%,
            transparent
        );
    background-size: 50px 50px;
}

.label-designer-toolbar {
    width: 100%;
    height: 35px;

    background-color: white;
    border-top: solid;
    border-top-width: 1px;
    border-top-color: #a7a7a799;

    display: flex;
    align-items: center;

    padding-left: 0.1rem;
    padding-right: 0.1rem;
    gap: 0.1rem;
}

.label-designer-toolbar-button {
    width: 30px;
    height: 30px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 5px;
    cursor: pointer;

    transition: 250ms;

    &:hover {
        background: #cecece;
    }

    &:active {
        transform: scale(0.8);
    }
}

.label-designer-component-button {
    width: 45px;
    height: 45px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 5px;
    cursor: pointer;

    transition: 250ms;
    // background: #1c1c1c;
    color: white;

    &:hover {
        border-radius: 0px;
        width: 65px;
        height: 65px;
        margin-top: 5px;
        margin-bottom: 5px;

        p {
            overflow: visible;
            height: 15px;
            opacity: 1;
        }
    }

    &:active {
        transform: scale(0.8);
        border-radius: 5px;
    }

    p {
        font-size: 12px;
        height: 0px;
        overflow: hidden;
        transition: 250ms;
        opacity: 0;
    }
}
