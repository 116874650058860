.lead-flow-icon-button {
    appearance: none;
    border: none;

    transition: 250ms;
    cursor: pointer;

    background: #00000020;
    // border: 1px solid #00000050;
    color: black;

    width: 35px;
    height: 35px;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background: #00000040;
        transform: scale(1.1);
    }

    &:active {
        transform: scale(0.9);
    }
}

.lead-flow-icon-button-light-on-white {
    background: transparent;
    &:hover {
        background: #00000020;
    }
}

.lead-flow-icon-button-light {
    // background: #ffffff10;
    background: transparent;
    color: white;
    &:hover {
        background: #ffffff10;
    }
}

.lead-flow-icon-button-primary {
    // background: #ffffff10;
    background: #0f62fe;
    color: white;
    &:hover {
        background: #0353e9;
    }
}

.lead-flow-icon-button-success {
    // background: #ffffff10;
    background: #006616;
    color: white;
    &:hover {
        background: #005012;
    }
}

.lead-flow-icon-button-secondary {
    // background: #ffffff10;
    background: #232323;
    color: white;
    &:hover {
        background: #000000;
    }
}

.lead-flow-icon-button-secondary-2 {
    // background: #ffffff10;
    background: #2d2d2d;
    color: white;
    &:hover {
        background: #000000;
    }
}

.lead-flow-icon-button-danger {
    // background: #ffffff10;
    background: #990000;
    color: white;
    &:hover {
        background: #450000;
    }
}

.lead-flow-tabs {
    .bx--tabs--scrollable {
        width: unset !important;
        color: white !important;
    }

    .bx--tabs--scrollable
        .bx--tabs--scrollable__nav-item:hover
        .bx--tabs--scrollable__nav-link {
        color: #ffffffdd !important;
    }

    .bx--tabs--scrollable
        .bx--tabs--scrollable__nav-item--selected
        .bx--tabs--scrollable__nav-link,
    .bx--tabs--scrollable
        .bx--tabs--scrollable__nav-item--selected
        .bx--tabs--scrollable__nav-link:focus,
    .bx--tabs--scrollable
        .bx--tabs--scrollable__nav-item--selected
        .bx--tabs--scrollable__nav-link:active {
        color: white !important;
    }

    .bx--tabs--scrollable .bx--tabs--scrollable__nav-link {
        color: #ffffff99 !important;
    }
}

.lead-flow-table-item {
    transition: background-color 250ms;
    cursor: pointer;

    &:hover {
        background-color: #00000020;
    }

    &:active {
        background-color: #00000040;
    }
}
