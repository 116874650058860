.board-view-add-btn {
    cursor: pointer;
    transition: transform 250ms;

    &:hover {
        transform: scale(1.2);
    }

    &:active {
        transform: scale(0.9);
    }
}

.board-view {
    .card-view {
        transition: 250ms;

        border: 1px solid #ffffff20;
        color: white;

        // background: black;
        background-color: #00000080;

        &:hover {
            // background-color: #ffffff10;
            background-color: #00000040;

            box-shadow: 0px 4px 6px 0px rgba(255, 255, 255, 0.11),
                0px 1px 3px 0px rgba(255, 255, 255, 0.08) !important;
        }
    }

    .column-view {
        border-color: #ffffff20;
        // background: #ffffff20;
        background: #00000040;
    }

    .board-view-add-btn {
        border-color: #ffffff20;
        // background: #ffffff20;
        background: #00000040;
        color: white;
    }

    .board-view-toolbar {
        border-color: #ffffff20;
        background: #00000040;
        color: white;
    }

    .column-view-header {
        background: black;
        color: white;
    }

    .no-item-card {
        color: white;
    }

    .column-highlighted {
        background-color: rgb(255, 255, 136, 0.2);
    }

    .board-view-toolbar-btn {
        appearance: none;
        border: none;

        transition: 250ms;
        cursor: pointer;

        background: #00000040;
        border: 1px solid #00000050;
        color: white;

        width: 35px;
        height: 35px;
        border-radius: 50%;

        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            // background: white;
            background: black;
            transform: scale(1.1);
        }

        &:active {
            transform: scale(0.9);
        }
    }

    .board-view-toolbar-component {
        appearance: none;
        border: none;

        background: #00000040;
        border: 1px solid #00000050;
        color: white;

        height: 35px;
        border-radius: 50px;

        display: flex;
        // justify-content: center;
        align-items: center;

        input {
            width: 200px;
            appearance: none;
            border: none;
            background: transparent;
            padding-inline: 0.5rem;
            padding-block: 0.25rem;
            color: inherit;
            outline: none;
        }
    }
}

.board-view-dark {
    .board-view-toolbar-btn {
        background: #ffffff40;
        border: 1px solid #ffffff50;
        color: black;

        &:hover {
            background: white;
        }
    }

    .board-view-toolbar-component {
        background: #ffffff40;
        border: 1px solid #ffffff50;
        color: black;
    }

    .card-view {
        border: 1px solid #ffffff20;
        color: black;

        background-color: #ffffff40;

        &:hover {
            // background-color: #ffffff10;
            background-color: #ffffff80;
        }
    }

    .column-view {
        border-color: #ffffff20;
        background: #ffffff60;
    }

    .board-view-add-btn {
        border-color: #ffffff20;
        background: #ffffff60;
        color: black;
    }

    .board-view-toolbar {
        border-color: #ffffff20;
        background: #ffffff60;
        color: black;
    }

    .column-view-header {
        background: black;
        color: white;
    }

    .no-item-card {
        color: black;
    }

    .column-highlighted {
        background-color: rgb(255, 255, 136, 0.4);
    }
}

.board-view-delete {
    animation: board-view-delete-fade-in-anim 250ms;

    width: 100%;
    height: 3rem;

    border-top: 1px solid #ffffff20;

    background-color: #1c1c1c;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.15rem;

    transition: height 250ms;
}

@keyframes board-view-delete-fade-in-anim {
    0% {
        opacity: 0;
        height: 0px;
    }
    100% {
        opacity: 1;
        height: 3rem;
    }
}

/* dont change this, other components instead of column are using it as well */
@keyframes board-view-column-fade-in-anim {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes board-view-item-fade-in-anim {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes board-view-title-textbox-fade-in-anim {
    0% {
        width: 35px;
    }
    100% {
        width: 277px;
    }
}

@mixin auto-grow-text-area {
    padding: 0.5rem;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    font-family: Helvetica, sans-serif;
    font: inherit;
    grid-area: 1 / 1 / 2 / 2;
}

.auto-grow-text-area-wrap {
    display: grid;

    &::after {
        content: attr(data-replicated-value) " ";
        white-space: pre-wrap;
        visibility: hidden;

        @include auto-grow-text-area();
    }

    textarea {
        resize: none;
        overflow: hidden;

        @include auto-grow-text-area();
    }
}

.to-do-board-button-pop-over {
    background-color: white;
    box-shadow: 0px 13px 27px -5px rgba(50, 50, 93, 0.25),
        0px 8px 16px -8px rgba(0, 0, 0, 0.3),
        0px -6px 16px -6px rgba(0, 0, 0, 0.025);
    // padding: 1rem;
    // border-radius: 15px;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
}

.to-do-board-button-pop-over-root {
    transform-origin: top right;
    animation: to-do-board-button-pop-over-fade-in 250ms;
}

.to-do-board-selection-item {
    width: 300px;
    height: 45px;

    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-inline: 1rem;

    transition: background-color 250ms;
    cursor: pointer;

    &:hover {
        background-color: #0f62fe20;
    }
}

.to-do-board-selection-item[data-selected="true"] {
    background-color: #00999940;
}

@keyframes to-do-board-button-pop-over-fade-in {
    0% {
        opacity: 0;
        transform: scale(0.4);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
