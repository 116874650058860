

.grid-list-root {
    position: relative;
    width: 100%;

    transition: 250ms;
}

.grid-list-item {
    position: absolute;
    left: 0px;
    top: 0px;

    overflow: hidden;

    //background-color: red;

    transition: 250ms;
}