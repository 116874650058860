.report-engine-card {
    background: linear-gradient(315deg, darken(#631a86, 15%), #631a86);
}

.report-table-right-panel {
    animation: report-table-right-panel-fade-in 250ms;
}

.report-engine-bottom-bar-button {
    height: 100%;
    padding: 0;
    border: none;
    outline: none;
    font: inherit;
    color: inherit;
    background: none;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    padding-inline: 1rem;

    font-size: 12px;
}

.report-engine-bottom-bar-button-green {
    transition: 250ms;

    background-color: transparent;
    color: darkgreen;

    &:hover {
        background-color: darkgreen;
        color: white;
    }
}

.report-engine-bottom-bar-button-blue {
    transition: 250ms;

    background-color: transparent;
    color: #0f62fe;

    &:hover {
        background-color: #0f62fe;
        color: white;
    }
}

.report-engine-menu-button {
    height: 20px;
    width: 30px;

    border-radius: 5px;

    padding: 0;
    border: none;
    outline: none;
    font: inherit;
    color: inherit;
    background: none;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 12px;

    transition: 250ms;

    &:hover {
        background-color: #ffffff05;
    }
}

.report-engine-menu-button[data-active="true"] {
    background-color: #ffffff10;
}

@keyframes report-table-right-panel-fade-in {
    0% {
        opacity: 0;
        transform: translateX(40%);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes report-engine-menu-hint-fade-in {
    0% {
        opacity: 0;
        transform: translateY(-40%);
    }
    100% {
        opacity: 0.65;
        transform: translateY(0px);
    }
}

.report-engine-quick-filter-bar {
    input {
        height: 30px;
        background: #ffffff10;
        border-radius: 5px;
        border: 1px solid #00000099;
        color: white;

        padding-inline: 0.5rem;

        color-scheme: dark;
    }

    label {
        margin: 0px;
        margin-right: 0.15rem;
        color: rgb(255, 255, 255, 0.65) !important;
    }

    .filter-field {
        margin-right: 0.5rem;
    }
}

.report-engine-value-tag {
    cursor: pointer;

    transition: 250ms;

    &:hover {
        box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
            0px 2px 4px -1px rgba(0, 0, 0, 0.06);
        transform: translateY(-2px);
    }
}

.report-engine-value-plus-button {
    height: 20px;
    width: 20px;

    padding: 0;
    border: none;
    outline: none;
    font: inherit;
    color: inherit;
    background: none;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 12px;

    transition: 250ms;

    background-color: #cecece;
    color: black;
    border-radius: 25px;

    &:hover {
        background-color: #1c1c1c;
        color: white;
        transform: scale(1.1);
    }

    &:active {
        transform: scale(0.9);
    }
}

.report-engine-value-plus-button-for-dark-mode {
    background-color: #090909;
    color: white;
    border-radius: 25px;

    &:hover {
        background-color: #f1f1f1;
        color: black;
    }
}

@keyframes report-engine-sort-menu-fade-in {
    0% {
        opacity: 0;
        transform: scale(0.4);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes report-engine-advanced-filter-background-fade-in {
    0% {
        background: rgba(0, 0, 0, 0);
    }
    100% {
        background: rgba(0, 0, 0, 0.65);
    }
}

@keyframes report-engine-advanced-filter-panel-fade-in {
    0% {
        opacity: 0;
        transform: translateX(25%);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

.report-engine-column-menu {
    li {
        width: 100%;
        height: 45px;
        padding-inline: 1rem;
        display: flex;
        align-items: center;

        font-size: 12px;
        opacity: 0.65;

        cursor: pointer;
        transition: background-color 250ms;

        &:hover {
            background-color: #00000020;
        }

        border-bottom: 1px solid #00000020;

        &:last-child {
            border-bottom: none;
        }
    }
}

.report-engine-list-mode-top-right-button {
    appearance: none;
    border: none;

    transition: 250ms;
    cursor: pointer;

    background: #00000000;
    // border: 1px solid #00000050;
    color: black;
    opacity: 0.65;

    width: 36px;
    height: 36px;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background: #00000020;
        opacity: 1;
        transform: scale(1.1);
    }

    &:active {
        transform: scale(0.9);
    }
}

.list-mode-report-engine-quick-filter-bar {
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;

    padding-inline: 1rem;

    .filter-field {
        height: 100%;
    }

    input {
        height: 100%;
        padding-inline: 0.5rem;

        border: none;
        background: none;

        outline: none;
    }

    label {
        margin: 0px;
        margin-right: 0.15rem;
    }

    // .filter-field {
    //     margin-right: 0.5rem;
    // }
}

.list-mode-divider-quick-filter-bar {
    width: 1px;
    height: 50%;

    margin-inline: 1rem;

    background: #00000010;
}

.report-engine-filter-field-full-width {
    width: 100%;
    display: flex;
    align-items: center;

    input {
        flex: 1;
    }
}

.report-engine-menu-button-light {
    height: 20px;
    width: 30px;

    border-radius: 5px;

    padding: 0;
    border: none;
    outline: none;
    font: inherit;
    color: inherit;
    background: none;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 12px;

    transition: 250ms;

    &:hover {
        background-color: #ffffff60;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    }
}

.report-engine-menu-button-light[data-active="true"] {
    background-color: #ffffff;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
        0px 1px 2px 0px rgba(0, 0, 0, 0.06);
}

.report-engine-menu-button-light.rbt-re-menu-wide {
    width: unset !important;
    padding-inline: 0.5rem;
    gap: 0.15rem;
}

.report-engine-menu-button-black {
    height: 30px;
    width: 30px;

    border-radius: 5px;

    padding: 0;
    border: none;
    outline: none;
    font: inherit;
    color: inherit;
    background: none;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 12px;

    transition: 250ms;

    &:hover {
        background-color: #ffffff60;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    }
}

.report-engine-menu-button-black[data-active="true"] {
    background-color: #1c1c1c;
    color: white;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
        0px 1px 2px 0px rgba(0, 0, 0, 0.06);
}
