.happiness-slider {
  width: 100%;

  #btn--yp {
    box-sizing: content-box;
    position: fixed;
    z-index: 9;
    bottom: 1em;
    right: 1em;
    border: solid 1em transparent;
    width: 4.625em;
    height: 3.25em;
    background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/2017/icon-yp.svg)
      50%/cover content-box;
    font: 16px/ 1.25 trebuchet ms, sans-serif;
    text-indent: 200vw;
    text-shadow: none;
    filter: grayscale(1) drop-shadow(0 0 1px #e8e0e0);
    transition: 0.5s;
    white-space: nowrap;
  }
  #btn--yp:before {
    box-sizing: inherit;
    position: absolute;
    left: 0;
    bottom: 100%;
    margin: 1em -0.5em;
    padding: 0.5em;
    width: 100%;
    border-radius: 5px;
    background: #e8e0e0;
    color: #000;
    text-align: center;
    text-decoration: none;
    text-indent: 0vw;
    white-space: normal;
    animation: float 1s ease-in-out infinite alternate;
    content: attr(data-txt);
  }
  #btn--yp:hover,
  #btn--yp:focus {
    outline: none;
    filter: grayscale(0) drop-shadow(0 0 1px crimson);
  }
  
  @keyframes float {
    to {
      transform: translateY(0.75em);
    }
  }
  * {
    margin: 0;
    padding: 0;
    color: inherit;
    font: inherit;
  }

  
  form {
    --x: calc(var(--val)/100*(100% - 1em));
    position: relative;
    // width: 92vw;
    width: 100%;
    max-width: 600px;
    color: gainsboro;
    /* fallback for no CSS vars */
    color: HSL(
      calc((1 - var(--val)/100)*22 + var(--val)/100*49),
      calc((1 - var(--val)/100)*100% + var(--val)/100*100%),
      calc((1 - var(--val)/100)*54% + var(--val)/100*50%)
    );
    font: 2.5em/2 nunito, sans-serif;
  }
  
  label[for="mood"] {
    display: block;
    margin-bottom: 0.75em;
    font-size: 0.625em;
    counter-reset: val var(--val);
  }
  
  input[id="mood"] {
    display: block;
    width: 100%;
    height: 0.5em;
    background: none;
    cursor: pointer;
  }
  input[id="mood"],
  input[id="mood"]::-webkit-slider-thumb,
  input[id="mood"]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
  }
  input[id="mood"]::-webkit-slider-runnable-track {
    border: none;
    width: 100%;
    height: 0.5em;
    border-radius: 0.25em;
    background: #888b8d;
    /* works for no CSS vars here */
    background-image: linear-gradient(
      90deg,
      #ff6a13,
      currentcolor var(--x),
      transparent 0
    );
  }
  input[id="mood"]::-moz-range-track {
    border: none;
    width: 100%;
    height: 0.5em;
    border-radius: 0.25em;
    background: #888b8d;
    /* works for no CSS vars here */
    background-image: linear-gradient(
      90deg,
      #ff6a13,
      currentcolor var(--x),
      transparent 0
    );
  }
  input[id="mood"]::-ms-track {
    border: none;
    width: 100%;
    height: 0.5em;
    border-radius: 0.25em;
    background: #888b8d;
    /* works for no CSS vars here */
    background-image: linear-gradient(
      90deg,
      #ff6a13,
      currentcolor var(--x),
      transparent 0
    );
    color: transparent;
  }
  input[id="mood"]::-ms-fill-lower {
    border-radius: 0.25em 0 0 0.25em;
    background-image: linear-gradient(90deg, #ff6a13, #ffd100);
    /* no var fallback */
    background-image: linear-gradient(90deg, #ff6a13, var(--c, currentcolor));
  }
  input[id="mood"]::-webkit-slider-thumb {
    border: none;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    /* some visual fallback if no emoji */
    box-shadow: inset 0 0 0 2px #eee;
    background: #222;
    margin-top: -0.25em;
  }
  input[id="mood"]::-moz-range-thumb {
    border: none;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    /* some visual fallback if no emoji */
    box-shadow: inset 0 0 0 2px #eee;
    background: #222;
  }
  input[id="mood"]::-ms-thumb {
    border: none;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    /* some visual fallback if no emoji */
    box-shadow: inset 0 0 0 2px #eee;
    background: #222;
    margin-top: 0;
  }
  input[id="mood"]:focus {
    outline: dashed 1px #ff6a13;
  }
  
  output[for="mood"] {
    /* for browsers that don't support vars, 
     * so won't place the emoji correctly even if 
     * they display it and not show a rectangle */
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -0.25em;
    left: var(--x);
    width: 1em;
    height: 1em;
    pointer-events: none;
  }
  
  /* if CSS variables are supported */
  @supports (margin: var(--m, 1em)) {
    label[for="mood"]:after {
      content: ": " counter(val) "%";
    }
  
    output[for="mood"] {
      display: flex;
    }
  }
  
  
}