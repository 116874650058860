.cinema-demo-root {
    background: radial-gradient(circle at center, red 0, blue, green 100%);
}

.cinema-demo-day-picker-show-time-scheduler-button {
    opacity: 0.65;
    transition: 250ms;

    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    outline: inherit;

    &:disabled {
        opacity: 0.25;
    }

    &:enabled {
        cursor: pointer;
        &:hover {
            opacity: 1;
        }
    }
}

.cinema-demo-header-button {
    width: 6rem;
    height: 6rem;

    display: flex;
    justify-content: center;
    align-items: center;

    color: white;

    transition: 250ms;

    &:hover {
        background-color: #00000015;
    }
    &:active {
        background-color: #00000025;
    }
}
