.table-designer-canvas {
    padding: 0px;
    border-radius: 10px;
    border: 1px solid #ffffff20;
    background-color: #0a0a0a;

    background-image: repeating-linear-gradient(
            0deg,
            transparent,
            transparent calc(50px - 1px),
            #ffffff10 calc(50px - 1px),
            #ffffff10 50px
        ),
        repeating-linear-gradient(
            -90deg,
            transparent,
            transparent calc(50px - 1px),
            #ffffff10 calc(50px - 1px),
            #ffffff10 50px
        );
    background-size: 50px 50px;
    overflow: hidden;
}

.table-designer-table-group {
    box-shadow: 0px 0px 68px 0px rgba(184, 115, 11, 0.27);
}

.table-designer-root {
    position: relative;

    display: flex;
    overflow: auto;
    // justify-content: center;
    // align-items: center;

    // background-color: #1c1c1c;
}

.table-designer-root-grid {
    background-image: 
    // linear-gradient(to right, grey 1px, transparent 1px),
    // linear-gradient(to bottom, grey 1px, transparent 1px);
        linear-gradient(
            0deg,
            transparent 9%,
            rgba(0, 0, 0, 0.2) 10%,
            rgba(0, 0, 0, 0.2) 12%,
            transparent 13%,
            transparent 29%,
            rgba(0, 0, 0, 0.1) 30%,
            rgba(0, 0, 0, 0.1) 31%,
            transparent 32%,
            transparent 49%,
            rgba(0, 0, 0, 0.1) 50%,
            rgba(0, 0, 0, 0.1) 51%,
            transparent 52%,
            transparent 69%,
            rgba(0, 0, 0, 0.1) 70%,
            rgba(0, 0, 0, 0.1) 71%,
            transparent 72%,
            transparent 89%,
            rgba(0, 0, 0, 0.1) 90%,
            rgba(0, 0, 0, 0.1) 91%,
            transparent 92%,
            transparent
        ),
        linear-gradient(
            90deg,
            transparent 9%,
            rgba(0, 0, 0, 0.2) 10%,
            rgba(0, 0, 0, 0.2) 12%,
            transparent 13%,
            transparent 29%,
            rgba(0, 0, 0, 0.1) 30%,
            rgba(0, 0, 0, 0.1) 31%,
            transparent 32%,
            transparent 49%,
            rgba(0, 0, 0, 0.1) 50%,
            rgba(0, 0, 0, 0.1) 51%,
            transparent 52%,
            transparent 69%,
            rgba(0, 0, 0, 0.1) 70%,
            rgba(0, 0, 0, 0.1) 71%,
            transparent 72%,
            transparent 89%,
            rgba(0, 0, 0, 0.1) 90%,
            rgba(0, 0, 0, 0.1) 91%,
            transparent 92%,
            transparent
        );
    background-size: 50px 50px;
}
