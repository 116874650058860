.shipment-item-menu-button {
    height: 20px;
    // width: 30px;
    // padding-inline: 1rem;

    border-radius: 5px;

    // padding-block: 0;
    padding: 0px;
    border: none;
    outline: none;
    font: inherit;
    color: inherit;
    background: none;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.15rem;

    font-size: 12px;

    transition: 250ms;

    &:hover {
        background-color: #ffffff05;
    }
}

.shipment-item-menu-button[data-active="true"] {
    background-color: #ffffff10;
}

@keyframes shipment-item-menu-hint-fade-in {
    0% {
        opacity: 0;
        transform: translateY(-40%);
    }
    100% {
        opacity: 0.65;
        transform: translateY(0px);
    }
}

@keyframes shipment-item-right-panel-fade-in {
    0% {
        opacity: 0;
        // transform: translateX(40%);
        width: 100px;
    }
    100% {
        opacity: 1;
        // transform: translateX(0px);
        width: 450px;
    }
}

.shipment-status-item {
    background: #00000040;

    transition: 250ms;

    &:hover {
        background: #000000aa;
    }
}

.shipment-side-view-entry-record {
    transition: background 250ms;
    cursor: pointer;

    &:hover {
        background: #00000010;
    }
}
