.sales-work-list-view-0 {
    background: linear-gradient(315deg, darken(#631a86, 15%), #631a86);

    h3 {
        font-size: 20px;
        //color: lighten(#631A86, 25%);
        color: white;
        font-weight: 600;
    }
}

.sales-work-list-view-1 {
    background: linear-gradient(315deg, darken(#6f2b07, 15%), #6f2b07);

    h3 {
        font-size: 20px;
        //color: lighten(#631A86, 25%);
        color: white;
        font-weight: 600;
    }
}

.sales-work-list-view-2 {
    background: linear-gradient(315deg, darken(#165702, 15%), #165702);

    h3 {
        font-size: 20px;
        //color: lighten(#631A86, 25%);
        color: white;
        font-weight: 600;
    }
}

.sales-work-list-view-3 {
    background: linear-gradient(315deg, darken(#3a3a3a, 15%), #3a3a3a);

    h3 {
        font-size: 20px;
        //color: lighten(#631A86, 25%);
        color: white;
        font-weight: 600;
    }
}
