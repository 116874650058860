@use "sass:color";



.bx--header {
    transition: background-color 250ms;
}

.payment-widget {
    background-color: black;
}

.dashboard-card {
    //widget bg only [its being used in POS page as well]
    background-color: #f4f4f4 !important;
}

.theme-original {

    $color: #161616;
    
    .theme-raw-bg {
        background: $color;
    }

    .theme-bg {
        background: lighten($color, 15%);
    }
    
    .profile-card {
        background: #161616;
    }

    .scroll-to-top-btn {
        background-color: #161616;

        &:hover {
            color: #0f62fe;
        }
    }

    .payment-widget {
        background-color: black;
    }

    .dashboard-card {
        background-color: #f4f4f4 !important;
    }

    .pos-barcode-input {
        outline: 2px solid #0f62fe !important;
    }
}

.theme-orange {
    $color: rgb(19,57,59);

    .theme-raw-bg {
        background: $color;
    }

    .theme-bg {
        background: lighten($color, 15%);
    }

    .profile-card {
        background: rgb(19,57,59);
    }

    .scroll-to-top-btn {
        background-color: rgb(19,57,59);

        &:hover {
            color: lighten(rgb(19,57,59), 25%);
        }
    }

    .bx--header {
        // background-color: #ff2800;
        background-color: rgb(19,57,59);
        border-bottom: 1px solid lighten(rgb(19,57,59), 15%) !important
    }

    .bx--btn--primary:not(.bx--header__action):not(.bx--btn:disabled) {
        background-color: rgb(19,57,59);
    
        &:active {
            background-color: darken(rgb(19,57,59), 15%) !important;
        }
        
        &:hover {
            background-color: lighten(rgb(19,57,59), 15%);
        }
    }

    .payment-widget {
        background-color: rgb(19,57,59);

        .bx--btn--primary:not(.bx--header__action):not(.bx--btn:disabled) {
            background-color: #393939;
        
            &:active {
                background-color: darken(#393939, 15%) !important;
            }
            
            &:hover {
                background-color: lighten(#393939, 15%);
            }
        }
    }

    .bx--btn--tertiary:not(.bx--header__action):not(.bx--btn:disabled) {
        border-color: rgb(19,57,59);
        color: rgb(19,57,59);
    
        &:active {
            background-color: darken(rgb(19,57,59), 15%) !important;
            border-color: darken(rgb(19,57,59), 15%) !important;
            color: white;
        }
        
        &:hover {
            background-color: lighten(rgb(19,57,59), 15%);
            border-color: lighten(rgb(19,57,59), 15%);
            color: white;
        }
    }

    .bx--text-input:focus, .bx--text-input:active {
        outline: 2px solid rgb(19,57,59) !important;
    }

    .pos-barcode-input {
        outline: 2px solid rgb(19,57,59) !important;
    }

    .bx--header-panel {
        background: darken(rgb(19,57,59), 5%) !important;
    }

    .bx--header__action--active {
        border-right: 1px solid lighten(rgb(19,57,59), 15%);
        border-bottom: 1px solid lighten(rgb(19,57,59), 5%);
        border-left: 1px solid lighten(rgb(19,57,59), 15%);
    }

    .bx--header__action:hover {
        background-color: lighten(rgb(19,57,59), 15%);
    }

    .dashboard-card {
        background-color: lighten(rgb(19,57,59), 80%) !important;
    }

    .bx--data-table-header {
        background-color: lighten(rgb(19,57,59), 80%) !important;
    }

    .bx--toolbar-content {
        background-color: lighten(rgb(19,57,59), 80%) !important;
    } 

    .bx--data-table td, .bx--data-table tbody th {
        background-color: lighten(rgb(19,57,59), 80%);
    }

    .bx--data-table tbody tr:hover td, .bx--data-table tbody tr:hover th {
        background-color: lighten(rgb(19,57,59), 70%);
    }

    .bx--table-sort:hover {
        background-color: lighten(rgb(19,57,59), 55%);
    }

    .bx--table-sort {
        background-color: lighten(rgb(19,57,59), 60%);
    }

}

.theme-blue {
    $color: #17255A;

    .theme-raw-bg {
        background: $color;
    }
    
    .theme-bg {
        background: lighten($color, 15%);
    }

    .profile-card {
        background: #17255A;
    }


    .scroll-to-top-btn {
        background-color: #17255A;

        &:hover {
            color: lighten(#17255A, 25%);
        }
    }

    .bx--header {
        background-color: #17255A;
        border-bottom: 1px solid lighten(#17255A, 15%) !important
    }

    .bx--btn--primary:not(.bx--header__action):not(.bx--btn:disabled) {
        background-color: #17255A;
    
        &:active {
            background-color: darken(#17255A, 15%) !important;
        }
        
        &:hover {
            background-color: lighten(#17255A, 15%);
        }
    }

    .payment-widget {
        background-color: #17255A;

        .bx--btn--primary:not(.bx--header__action):not(.bx--btn:disabled) {
            background-color: #393939;
        
            &:active {
                background-color: darken(#393939, 15%) !important;
            }
            
            &:hover {
                background-color: lighten(#393939, 15%);
            }
        }
    }

    .bx--btn--tertiary:not(.bx--header__action):not(.bx--btn:disabled) {
        border-color: #17255A;
        color: #17255A;
    
        &:active {
            background-color: darken(#17255A, 15%) !important;
            border-color: darken(#17255A, 15%) !important;
            color: white;
        }
        
        &:hover {
            background-color: lighten(#17255A, 15%);
            border-color: lighten(#17255A, 15%);
            color: white;
        }
    }

    .bx--text-input:focus, .bx--text-input:active {
        outline: 2px solid #17255A !important;
    }

    .pos-barcode-input {
        outline: 2px solid #17255A !important;
    }

    .bx--header-panel {
        background: darken(#17255A, 5%) !important;
    }

    .bx--header__action--active {
        border-right: 1px solid lighten(#17255A, 15%);
        border-bottom: 1px solid lighten(#17255A, 5%);
        border-left: 1px solid lighten(#17255A, 15%);
    }

    .bx--header__action:hover {
        background-color: lighten(#17255A, 15%);
    }

    .dashboard-card {
        background-color: lighten(#17255A, 75%) !important;
    }

    .bx--data-table-header {
        background-color: lighten(#17255A, 75%) !important;
    }

    .bx--toolbar-content {
        background-color: lighten(#17255A, 75%) !important;
    } 

    .bx--data-table td, .bx--data-table tbody th {
        background-color: lighten(#17255A, 75%);
    }

    .bx--data-table tbody tr:hover td, .bx--data-table tbody tr:hover th {
        background-color: lighten(#17255A, 60%);
    }

    .bx--table-sort:hover {
        background-color: lighten(#17255A, 40%);
    }

    .bx--table-sort {
        background-color: lighten(#17255A, 50%);
    }

}

.theme-green {
    $color: #4A5240;

    .theme-raw-bg {
        background: $color;
    }
    
    .theme-bg {
        background: lighten($color, 15%);
    }

    .profile-card {
        background: #4A5240;
    }


    .scroll-to-top-btn {
        background-color: #4A5240;

        &:hover {
            color: lighten(#4A5240, 25%);
        }
    }

    .bx--header {
        background-color: #4A5240;
        border-bottom: 1px solid lighten(#4A5240, 15%) !important
    }

    .bx--btn--primary:not(.bx--header__action):not(.bx--btn:disabled) {
        background-color: #4A5240;
    
        &:active {
            background-color: darken(#4A5240, 15%) !important;
        }
        
        &:hover {
            background-color: lighten(#4A5240, 15%);
        }
    }


    .payment-widget {
        background-color: #4A5240;

        .bx--btn--primary:not(.bx--header__action):not(.bx--btn:disabled) {
            background-color: #393939;
        
            &:active {
                background-color: darken(#393939, 15%) !important;
            }
            
            &:hover {
                background-color: lighten(#393939, 15%);
            }
        }
    }

    .bx--btn--tertiary:not(.bx--header__action):not(.bx--btn:disabled) {
        border-color: #4A5240;
        color: #4A5240;
    
        &:active {
            background-color: darken(#4A5240, 15%) !important;
            border-color: darken(#4A5240, 15%) !important;
            color: white;
        }
        
        &:hover {
            background-color: lighten(#4A5240, 15%);
            border-color: lighten(#4A5240, 15%);
            color: white;
        }
    }

    .bx--text-input:focus, .bx--text-input:active {
        outline: 2px solid #4A5240 !important;
    }

    .pos-barcode-input {
        outline: 2px solid #4A5240 !important;
    }

    .bx--header-panel {
        background: darken(#4A5240, 15%) !important;
    }

    .bx--header__action--active {
        border-right: 1px solid lighten(#4A5240, 15%);
        border-bottom: 1px solid lighten(#4A5240, 5%);
        border-left: 1px solid lighten(#4A5240, 15%);
    }

    .bx--header__action:hover {
        background-color: lighten(#4A5240, 15%);
    }

    .dashboard-card {
        background-color: lighten(#4A5240, 60%) !important;
    }

    .bx--data-table-header {
        background-color: lighten(#4A5240, 60%) !important;
    }

    .bx--toolbar-content {
        background-color: lighten(#4A5240, 60%) !important;
    } 

    .bx--data-table td, .bx--data-table tbody th {
        background-color: lighten(#4A5240, 60%);
    }

    .bx--data-table tbody tr:hover td, .bx--data-table tbody tr:hover th {
        background-color: lighten(#4A5240, 50%);
    }

    .bx--table-sort:hover {
        background-color: lighten(#4A5240, 25%);
    }

    .bx--table-sort {
        background-color: lighten(#4A5240, 35%);
    }

}

.theme-red {
    $color: #500000;
    
    .theme-raw-bg {
        background: $color;
    }

    .theme-bg {
        background: lighten($color, 15%);
    }

    .profile-card {
        background: #500000;
    }


    .scroll-to-top-btn {
        background-color: #500000;

        &:hover {
            color: lighten(#500000, 25%);
        }
    }

    .bx--header {
        background-color: #500000;
        border-bottom: 1px solid lighten(#500000, 15%) !important
    }

    .bx--btn--primary:not(.bx--header__action):not(.bx--btn:disabled) {
        background-color: #500000;
    
        &:active {
            background-color: darken(#500000, 15%) !important;
        }
        
        &:hover {
            background-color: lighten(#500000, 15%);
        }
    }


    
    .payment-widget {
        background-color: #500000;

        .bx--btn--primary:not(.bx--header__action):not(.bx--btn:disabled) {
            background-color: #393939;
        
            &:active {
                background-color: darken(#393939, 15%) !important;
            }
            
            &:hover {
                background-color: lighten(#393939, 15%);
            }
        }
    }

    .bx--btn--tertiary:not(.bx--header__action):not(.bx--btn:disabled) {
        border-color: #500000;
        color: #500000;
    
        &:active {
            background-color: darken(#500000, 15%) !important;
            border-color: darken(#500000, 15%) !important;
            color: white;
        }
        
        &:hover {
            background-color: lighten(#500000, 15%);
            border-color: lighten(#500000, 15%);
            color: white;
        }
    }

    .bx--text-input:focus, .bx--text-input:active {
        outline: 2px solid #500000 !important;
    }

    .pos-barcode-input {
        outline: 2px solid #500000 !important;
    }

    .bx--header-panel {
        background: darken(#500000, 5%) !important;
    }

    .bx--header__action--active {
        border-right: 1px solid lighten(#500000, 15%);
        border-bottom: 1px solid lighten(#500000, 5%);
        border-left: 1px solid lighten(#500000, 15%);
    }

    .bx--header__action:hover {
        background-color: lighten(#500000, 15%);
    }

    .dashboard-card {
        background-color: lighten(#500000, 80%) !important;
    }

    .bx--data-table-header {
        background-color: lighten(#500000, 80%) !important;
    }

    .bx--toolbar-content {
        background-color: lighten(#500000, 80%) !important;
    } 

    .bx--data-table td, .bx--data-table tbody th {
        background-color: lighten(#500000, 80%);
    }

    .bx--data-table tbody tr:hover td, .bx--data-table tbody tr:hover th {
        background-color: lighten(#500000, 70%);
    }

    .bx--table-sort:hover {
        background-color: lighten(#500000, 60%);
    }

    .bx--table-sort {
        background-color: lighten(#500000, 65%);
    }

}

.theme-pink {

    $color: #8f4e5d;

    .theme-raw-bg {
        background: $color;
    }
    
    .theme-bg {
        background: lighten($color, 15%);
    }

    .profile-card {
        background: #8f4e5d;
    }

    .scroll-to-top-btn {
        background-color: #8f4e5d;

        &:hover {
            color: lighten(#8f4e5d, 25%);
        }
    }


    .bx--header {
        background-color: #8f4e5d;
        border-bottom: 1px solid lighten(#8f4e5d, 15%) !important;
    }

    .bx--btn--primary:not(.bx--header__action):not(.bx--btn:disabled) {
        background-color: #8f4e5d;
    
        &:active {
            background-color: darken(#8f4e5d, 15%) !important;
        }
        
        &:hover {
            background-color: lighten(#8f4e5d, 15%);
        }
    }




    .payment-widget {
        background-color: #8f4e5d;

        .bx--btn--primary:not(.bx--header__action):not(.bx--btn:disabled) {
            background-color: #393939;
        
            &:active {
                background-color: darken(#393939, 15%) !important;
            }
            
            &:hover {
                background-color: lighten(#393939, 15%);
            }
        }
    }

    .bx--btn--tertiary:not(.bx--header__action):not(.bx--btn:disabled) {
        border-color: #8f4e5d;
        color: #8f4e5d;
    
        &:active {
            background-color: darken(#8f4e5d, 15%) !important;
            border-color: darken(#8f4e5d, 15%) !important;
            color: white;
        }
        
        &:hover {
            background-color: lighten(#8f4e5d, 15%);
            border-color: lighten(#8f4e5d, 15%);
            color: white;
        }
    }

    .bx--text-input:focus, .bx--text-input:active {
        outline: 2px solid #8f4e5d !important;
    }

    .pos-barcode-input {
        outline: 2px solid #8f4e5d !important;
    }

    .bx--header-panel {
        background: darken(#8f4e5d, 25%) !important;
    }

    .bx--header__action--active {
        border-right: 1px solid lighten(#8f4e5d, 15%);
        border-bottom: 1px solid lighten(#8f4e5d, 5%);
        border-left: 1px solid lighten(#8f4e5d, 15%);
    }

    .bx--header__action:hover {
        background-color: lighten(#8f4e5d, 15%);
    }

    .dashboard-card {
        background-color: lighten(#8f4e5d, 50%) !important;
    }

    .bx--data-table-header {
        background-color: lighten(#8f4e5d, 50%) !important;
    }

    .bx--toolbar-content {
        background-color: lighten(#8f4e5d, 50%) !important;
    } 

    .bx--data-table td, .bx--data-table tbody th {
        background-color: lighten(#8f4e5d, 50%);
    }

    .bx--data-table tbody tr:hover td, .bx--data-table tbody tr:hover th {
        background-color: lighten(#8f4e5d, 45%);
    }

    .bx--table-sort:hover {
        background-color: lighten(#8f4e5d, 30%);
    }

    .bx--table-sort {
        background-color: lighten(#8f4e5d, 40%);
    } 

}