.pos {
    .barcode-widget {
        .bx--form-item {
            flex: 0;
        }
    }

    .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--tooltip--visible
        .bx--assistive-text,
    .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--tooltip--visible
        + .bx--assistive-text,
    .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--tooltip--visible.bx--tooltip--a11y::before,
    .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover .bx--assistive-text,
    .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover
        + .bx--assistive-text,
    .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover.bx--tooltip--a11y::before,
    .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus .bx--assistive-text,
    .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus
        + .bx--assistive-text,
    .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus.bx--tooltip--a11y::before {
        display: none !important;
    }

    .bx--assistive-text {
        display: none !important;
    }
}

.react-grid-item {
    touch-action: auto !important;
}

.pos-tooltip {
    z-index: 100000000 !important;
    opacity: 1 !important;
}

.pos-dialog-overlay {
    background: rgba(0, 0, 0, 0.9) !important;
}

.react-confirm-alert-overlay {
    z-index: 100000000 !important;
}

// .react-confirm-alert {
//     z-index: 100000000 !important;
// }

.react-grid-item > .react-resizable-handle::after {
    //display: none;
    border-right: 2px solid rgba(255, 255, 255, 0.4) !important;
    border-bottom: 2px solid rgba(255, 255, 255, 0.4) !important;
}
.payment-text-input {
    pointer-events: none;
    width: 140px;

    .bx--label {
        color: rgba(255, 255, 255, 0.65);
        margin-bottom: 2px;
    }
}

.no-padding-menu {
    .bx--list-box__menu-item__option {
        padding: 0px !important;
    }
}

.large-search-box {
    .bx--search {
        width: unset;
        flex: 1;
    }

    .bx--search-close {
        width: 48px;
        height: 48px;
    }
}

.manage-pos-dialog-button {
    width: 65px;
    height: 65px;
    border-radius: 7px;
    background: white;
    color: #1c1c1c;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
        0px 4px 6px -2px rgba(0, 0, 0, 0.05);

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.15rem;

    transition: 250ms;
    cursor: pointer;

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1),
            0px 10px 10px -5px rgba(0, 0, 0, 0.04);

        background: #1c1c1c;
        color: white;
    }
}
