.seat-node {
    width: 16px;
    height: 16px;

    transition: 250ms;
    opacity: 0.1;

    &:hover {
        opacity: 0.65;
    }
}

.seat-node-enabled {
    width: 16px;
    height: 16px;

    border-radius: 50px;

    transition: opacity 250ms;
    opacity: 1;

    &:hover {
        opacity: 0.65;
    }
}

.seat-designer-clear-selection-button {
    display: flex;
    align-items: center;
    color: white;
    gap: 0.5rem;

    position: absolute;
    top: 1rem;
    right: 1rem;

    padding-inline: 1rem;
    padding-block: 0.25rem;
    background: black;

    border: 1px solid #ffffff40;
    border-radius: 50px;

    opacity: 0.65;
    transition: 250ms;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }

    transform-origin: top right;
    animation: seat-designer-clear-selection-button-fade-in 250ms;
}

.seat-designer-legend-bar {
    display: flex;
    align-items: center;
    color: white;
    gap: 0.5rem;

    position: absolute;
    bottom: 1rem;
    left: 1rem;

    padding-inline: 1rem;
    padding-block: 0.25rem;
    background: black;

    border: 1px solid #ffffff40;
    border-radius: 50px;

    opacity: 0.65;
}

@keyframes seat-designer-clear-selection-button-fade-in {
    0% {
        opacity: 0;
        transform: translateX(45%);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

.counter-button-seat-grid {
    background: transparent;
    width: 100%;
    height: 30px;
    color: white;

    transition: 250ms;

    display: flex;
    justify-content: center;
    align-items: center;

    &:enabled {
        cursor: pointer;
        &:hover {
            background: #ffffff20;
        }
    }

    &:disabled {
        opacity: 0.65;
    }

    border: none;
    padding: 0;
    font: inherit;
    outline: inherit;
}

.cinema-screen {
    position: absolute;
    left: 50%;
    top: 1.5rem;
    transform: translateX(-50%);

    width: 300px;
    height: 50px;
    border: solid 2px;
    border-color: #ffffff40 transparent transparent transparent;
    border-radius: 50% 50% 0 0;

    // background: #fff;
    // height: 70px;
    // width: 250px;
    // transform: rotateX(-45deg);
    // box-shadow: 0 3px 10px rgba(255, 255, 255, 0.7);

    // margin-bottom: 3rem;
}

.seat-designer-canvas {
    min-width: 300px;
    padding: 0px;
    // padding: 1rem;
    // padding-top: 0.75rem;

    border-radius: 10px;
    border: 1px solid #ffffff40;

    background-color: #0a0a0a;

    box-shadow: 0px 0px 68px 0px rgba(184, 115, 11, 0.27);
}

.seat-designer-root {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #1c1c1c;
}

.seat-designer-root-grid {
    background-image: 
    // linear-gradient(to right, grey 1px, transparent 1px),
    // linear-gradient(to bottom, grey 1px, transparent 1px);
        linear-gradient(
            0deg,
            transparent 9%,
            rgba(0, 0, 0, 0.2) 10%,
            rgba(0, 0, 0, 0.2) 12%,
            transparent 13%,
            transparent 29%,
            rgba(0, 0, 0, 0.1) 30%,
            rgba(0, 0, 0, 0.1) 31%,
            transparent 32%,
            transparent 49%,
            rgba(0, 0, 0, 0.1) 50%,
            rgba(0, 0, 0, 0.1) 51%,
            transparent 52%,
            transparent 69%,
            rgba(0, 0, 0, 0.1) 70%,
            rgba(0, 0, 0, 0.1) 71%,
            transparent 72%,
            transparent 89%,
            rgba(0, 0, 0, 0.1) 90%,
            rgba(0, 0, 0, 0.1) 91%,
            transparent 92%,
            transparent
        ),
        linear-gradient(
            90deg,
            transparent 9%,
            rgba(0, 0, 0, 0.2) 10%,
            rgba(0, 0, 0, 0.2) 12%,
            transparent 13%,
            transparent 29%,
            rgba(0, 0, 0, 0.1) 30%,
            rgba(0, 0, 0, 0.1) 31%,
            transparent 32%,
            transparent 49%,
            rgba(0, 0, 0, 0.1) 50%,
            rgba(0, 0, 0, 0.1) 51%,
            transparent 52%,
            transparent 69%,
            rgba(0, 0, 0, 0.1) 70%,
            rgba(0, 0, 0, 0.1) 71%,
            transparent 72%,
            transparent 89%,
            rgba(0, 0, 0, 0.1) 90%,
            rgba(0, 0, 0, 0.1) 91%,
            transparent 92%,
            transparent
        );
    background-size: 50px 50px;
}
