.final-receipt-dialog {
    .backdrop {
        background: #000000cc;
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100vw;
        height: 100vh;
        z-index: 500;

        transition: opacity 250ms;

        backdrop-filter: blur(12px);
    }

    .card {
        border-radius: 15px;
        width: 600px;
        position: fixed;
        z-index: 500;
        left: 50%;
        top: 50%;
        overflow: hidden;

        transition: transform 250ms, opacity 250ms;

        background: white;

        display: flex;
        flex-direction: column;
    }

    .item {
        transition: background 250ms;
        cursor: pointer;

        &:hover {
            background: #00000020;
        }
    }

    .backdrop-visible {
        opacity: 1;
        pointer-events: inherit;
    }

    .backdrop-hidden {
        opacity: 0;
        pointer-events: none;
    }

    .card-visible {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
        pointer-events: inherit;
    }

    .card-hidden {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.75);
        pointer-events: none;
    }
}
