.sales-order-view-0 {
    background: linear-gradient(315deg, darken(#631a86, 15%), #631a86);

    h3 {
        font-size: 20px;
        //color: lighten(#631A86, 25%);
        color: white;
        font-weight: 600;
    }
}

.sales-order-view-1 {
    background: linear-gradient(315deg, darken(#6f2b07, 15%), #6f2b07);

    h3 {
        font-size: 20px;
        //color: lighten(#631A86, 25%);
        color: white;
        font-weight: 600;
    }
}

.sales-order-view-2 {
    background: linear-gradient(315deg, darken(#165702, 15%), #165702);

    h3 {
        font-size: 20px;
        //color: lighten(#631A86, 25%);
        color: white;
        font-weight: 600;
    }
}

.sales-order-view-3 {
    background: linear-gradient(315deg, darken(#3a3a3a, 15%), #3a3a3a);

    h3 {
        font-size: 20px;
        //color: lighten(#631A86, 25%);
        color: white;
        font-weight: 600;
    }
}

.table-filter-pop-over {
    background-color: white;
    box-shadow: 0px 13px 27px -5px rgba(50, 50, 93, 0.25),
        0px 8px 16px -8px rgba(0, 0, 0, 0.3),
        0px -6px 16px -6px rgba(0, 0, 0, 0.025);
    // padding: 1rem;
}

.table-filter-pop-over-root {
    transform-origin: top right;
    animation: table-filter-pop-over-fade-in 250ms;
}

@keyframes table-filter-pop-over-fade-in {
    0% {
        opacity: 0;
        transform: scale(0.4);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
