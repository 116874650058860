.markup-template-engine {
}

.robo-ide-root {
    height: calc(100vh - 6rem);
    width: 100%;

    display: flex;

    .side-bar-opt {
        user-select: none;

        border-bottom: 1px solid #ffffff10;
        width: 100%;
        color: white;

        padding-inline: 1rem;
        // padding-block: 0.5rem;
        height: 38px;

        display: flex;
        align-items: center;
        gap: 0.25rem;

        transition: background 250ms, color 250ms;
        cursor: pointer;

        &:not([data-selected="true"]):hover {
            background: #ffffff10 !important;
        }

        &[data-selected="true"] {
            background: #0f62fe !important;
            color: white;
            cursor: default;
        }
    }

    .side-bar-opt > p {
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .side-bar-group-content {
        .side-bar-opt {
            padding-left: 2rem;
        }
    }

    .side-bar-group > .side-bar-opt {
        background: #ffffff05;
    }

    .arrow-handler {
        transition: 150ms;
    }

    .arrow-handler[data-expanded="true"] {
        transform: rotate(90deg);
    }
}

.robo-ide-side-bar {
    height: 100%;
    background: #1c1c1c;
    border-right: 1px solid #ffffff10;
}

.robo-ide-editor {
    flex: 1;
    min-width: 0;
}

.robo-ide-editor-status-bar {
    padding-inline: 1rem;
    height: 2rem;

    background: black;
    color: white;
    border-bottom: 1px solid #ffffff20;

    display: flex;
    align-items: center;
    gap: 0.25rem;
}

.changes-saved-message {
    color: #06c12f;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    margin-left: 1rem;
}

.unsaved-changes-warning {
    color: orange;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    margin-left: 1rem;
}

.readonly-message {
    color: #ffffff80;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    margin-left: 1rem;
}

.robo-ide-constants-tab {
    height: calc(100vh - 6rem);
    width: 100%;

    .robo-ide-editor {
        height: 100%;
    }
}

.robo-ide-resources-tab {
    height: calc(100vh - 6rem);
    width: 100%;
}

.robo-ide-fragments-tab {
    height: calc(100vh - 6rem);
    width: 100%;
}

.robo-res-item {
    color: white;
    border-right: 0.5px solid #ffffff10;
    border-bottom: 0.5px solid #ffffff10;

    & > p {
        font-weight: bold;
        font-size: 14px;
        text-align: center;
    }

    &.create-res-btn {
        transition: background-color 250ms;
        cursor: pointer;

        &:hover {
            background: #00000020;
        }
    }
}

// .robo-res-item + .robo-res-item {
//     border-left: 1px solid #ffffff10;
// }
