.recipe-manager-item-accordion {
    height: 48px;

    transition: 250ms;
    cursor: pointer;

    background-color: #0f62fe;
    &:hover {
        background-color: #0353e9;
    }
}

.recipe-manager-ingredient-select-item {
    cursor: pointer;
    transition: background-color 250ms;
    border-radius: 7px;
    &:hover {
        background-color: #00000015;
    }
}
