.cargo-customer {
    padding: 1rem;

    .logo {
        object-fit: contain;
        object-position: center;
        width: 200px;
    }

    .cargo-c-title {
        font-weight: bold;
        font-size: 22px;
        margin-top: 2rem;
    }

    .cargo-c-subtitle {
        font-size: 18px;
        margin-bottom: 1rem;
    }

    .cargo-c-balance {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
    }

    .cargo-inv-item {
        background: #f4f4f4;
        border: 1px solid #00000010;
        border-radius: 5px;
        padding: 1rem;
        margin-bottom: 0.5rem;
    }

    .cargo-inv-item-code {
        font-weight: bold;
        font-size: 14px;
    }

    .cargo-inv-item-details {
        margin-top: 1rem;
        font-size: 14px;
        justify-content: space-between;

        p {
            font-weight: bold;
        }
    }

    .cargo-inv-item-content-details {
        grid-template-columns: 1fr 1fr 1fr;

        @media (max-width: 768px) {
            grid-template-columns: 1fr 1fr;
        }
    }
}
