@keyframes cinema-pos-overlay-animation {
    0% {
        opacity: 0;
        transform: scale(0.8);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.cinema-pos-day-picker-show-time-scheduler-button {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;

    opacity: 0.65;
    transition: 250ms;

    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    outline: inherit;

    &:disabled {
        opacity: 0.25;
    }

    &:enabled {
        cursor: pointer;
        &:hover {
            opacity: 1;
        }
    }
}

.cinema-pos-scroll-bar {
    * {
        scrollbar-width: auto;
        scrollbar-color: #606060 #232323;
    }

    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 14px;
    }

    *::-webkit-scrollbar-track {
        background: #232323;
    }

    *::-webkit-scrollbar-thumb {
        background-color: #606060;
        border-radius: 10px;
        border: 3px solid #232323;
    }

    ::-webkit-scrollbar-corner {
        background: #232323;
    }
}
