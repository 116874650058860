.square-stat-root {
    .square-stat-title {
        font-size: 22px;
        font-weight: bold;
        opacity: 0;
        height: 0px;
        transition: 300ms;
    }
    .square-stat-value {
        font-size: 19px;
    }
    .square-stat-extend {
        transform-origin: top left;
        border-radius: 100px;
        opacity: 0;
        transform: scale(0);
        transition: 350ms;
    }

    &:hover {
        .square-stat {
            transform: scale(1.05);
            border-radius: 15px;
        }

        .square-stat-title {
            opacity: 1;
            height: 20px;
        }

        .square-stat-extend {
            opacity: 1;
            transform: scale(1);
            border-radius: 15px;
        }
    }
}

.square-stat {
    width: 100%;
    height: 100%;

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    gap: 0.5rem;

    user-select: none;
    border-radius: 25px;
    transition: 250ms;
}

.square-stat-0 {
    background: linear-gradient(
        315deg,
        darken(#ffffff, 15%),
        darken(#ffffff, 2%)
    );
    box-shadow: 0px 25px 50px -12px #00000060;
}

.square-stat-1 {
    background: linear-gradient(315deg, darken(#f45866, 15%), #f45866);
    box-shadow: 0px 25px 50px -12px #f45866aa;
    border: 2px solid #ffffff;
}

.square-stat-2 {
    background: linear-gradient(315deg, darken(#3083dc, 15%), #3083dc);
    box-shadow: 0px 25px 50px -12px #3083dcaa;
    border: 2px solid #ffffff;
}

.square-stat-3 {
    background: linear-gradient(315deg, darken(#197278, 15%), #197278);
    box-shadow: 0px 25px 50px -12px #197278aa;
    border: 2px solid #ffffff;
}

.square-stat-4 {
    background: linear-gradient(315deg, darken(#df7920, 15%), #df7920);
    box-shadow: 0px 25px 50px -12px #df7920aa;
    border: 2px solid #ffffff;
}

.square-stat-5 {
    background: linear-gradient(315deg, darken(#8258f4, 15%), #8258f4);
    box-shadow: 0px 25px 50px -12px #8258f4aa;
    border: 2px solid #ffffff;
}

.square-stat-6 {
    background: linear-gradient(315deg, darken(#631a86, 15%), #631a86);
    box-shadow: 0px 25px 50px -12px #631a86aa;
    border: 2px solid #ffffff;
}

.square-stat-1-mini {
    background: linear-gradient(
        315deg,
        darken(#f45866, 50%),
        darken(#f45866, 25%)
    );
    box-shadow: 0px 25px 50px -12px #f45866aa;
    border: 1px solid #ffffff;
}

.square-stat-2-mini {
    background: linear-gradient(
        315deg,
        darken(#3083dc, 50%),
        darken(#3083dc, 25%)
    );
    box-shadow: 0px 25px 50px -12px #3083dcaa;
    border: 1px solid #ffffff;
}

.square-stat-3-mini {
    background: linear-gradient(
        315deg,
        darken(#197278, 50%),
        darken(#197278, 25%)
    );
    box-shadow: 0px 25px 50px -12px #197278aa;
    border: 1px solid #ffffff;
}

.square-stat-4-mini {
    background: linear-gradient(
        315deg,
        darken(#df7920, 50%),
        darken(#df7920, 25%)
    );
    box-shadow: 0px 25px 50px -12px #df7920aa;
    border: 1px solid #ffffff;
}

.square-stat-5-mini {
    background: linear-gradient(
        315deg,
        darken(#8258f4, 50%),
        darken(#8258f4, 25%)
    );
    box-shadow: 0px 25px 50px -12px #8258f4aa;
    border: 1px solid #ffffff;
}

.square-stat-6-mini {
    background: linear-gradient(
        315deg,
        darken(#631a86, 50%),
        darken(#631a86, 25%)
    );
    box-shadow: 0px 25px 50px -12px #631a86aa;
    border: 1px solid #ffffff;
}

.map-property-marker {
    width: 32px;
    height: 32px;
    border-radius: 50px;

    background: #1c1c1c;
    border: 1px solid white;

    transition: 250ms;

    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    cursor: pointer;

    p {
        display: none;

        white-space: nowrap;
        font-size: 12px;
        opacity: 0;

        color: gold;

        transition: 250ms;
    }

    &:hover {
        width: 150px;

        p {
            display: block;
            opacity: 1;
        }
    }
}
